import React from "react"

import adapt_logo from "../../static/images/2/logos/adapt.svg"
import made_for_drink_logo from "../../static/images/2/logos/made_for_drink.svg"
import sophia_logo from "../../static/images/2/logos/sophia_logo.svg"
import sca_logo from "../../static/images/2/logos/SCA_LOGO.svg"

export const ClientLogoList = ({logos, size}) => {

  return (
    <div className={"logoGrid logoGrid_" + size.lg + " logoGrid_" + size.sm + "_sm" }>
      {(logos ? (
        logos.map((logo, key) => (
        <div className="logoGrid_item" key={key}>
          <img src={logo.src} alt={"brillea customer " + logo.name} />
        </div>
        ))
      ) : (
        <>
      <div className="logoGrid_item">
        <img src={adapt_logo} alt={"brillea customer adapt"} />
      </div>
      <div className="logoGrid_item">
        <img
          src={made_for_drink_logo}
          alt={"brillea customer Made For Drink"}
        />
      </div>
      <div className="logoGrid_item">
        <img src={sophia_logo} alt={"brillea customer Sophia"} />
      </div>
      <div className="logoGrid_item">
        <img
          src={sca_logo}
          alt={"brillea customer School of Communications arts 2.0"}
        />
        </div>
        </>
      ))}
    </div>
  )
}
